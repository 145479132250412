import React from "react"
import { ThemeProvider } from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"

import { PrimaryHeading, BodyText } from "../components/typography"
import { Layout, Section } from "../components/layout"
import Seo from "../components/Seo"
import siteTheme from "../constants/siteTheme"
import Hero from "../components/Hero"
import Link from "../components/Link"
import heroBackgroundImage from "../images/compressed/window-cleaning-edmonton-hero.jpg"

const NotFoundPage = () => (
  <ThemeProvider theme={siteTheme}>
    <Layout>
      <Seo title="404: Not found" />
      <Hero imageUrl={heroBackgroundImage}>
        <Grid>
          <PrimaryHeading className="centered">Page Not Found</PrimaryHeading>
        </Grid>
      </Hero>
      <Grid>
        <Section>
          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              <BodyText>
                Sorry, we couldn't find the page you requested. Please visit our{" "}
                <Link to="/">homepage</Link>, review{" "}
                <Link to="/services">our services</Link>, or request a{" "}
                <Link to="estimate">free estimate</Link>.
              </BodyText>
            </Col>
          </Row>
        </Section>
      </Grid>
    </Layout>
  </ThemeProvider>
)

export default NotFoundPage
